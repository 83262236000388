import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import CountDown from "../Helpers/CountDown";
import ModalCom from "../Helpers/ModalCom";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import ActiveJobMessage from "./ActiveJobMessage";
import IndexJobActions from "./JobActions/IndexJobActions";

import usersService from "../../services/UsersService";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import { SocketValues } from "../Contexts/SocketIOContext";
import TabButton from "../customTabs/TabButton";
import AttachFile from "../attachmentCom/AttachFile";

import JobDetailPopout from "./JobDetailPopout";

function ActiveJobs(props) {
  let {sendMessage, joinRoom} = SocketValues() // destructures 'SEND MESSAGE' and 'JOIN ROOM' FUNCTIONS FROM SOCKET
  
  const ApiCall = new usersService();
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.userDetails);

  const [passDue, setPassDue] = useState(
    new Date() > new Date(props.details?.delivery_date)
  ); // STATE TO KNOW IF TASK IS PASSED DUE TIME

  const [messageToSend, setMessageToSend] = useState(""); // State to hold the value of message to be sent

  const [filesToSend, setFilesToSend] = useState([]); // State to hold the value of files to be sent

  // const [tab, setTab] = useState("message");
  const tabs = ["Send Message", "Send Files",] //STATE FOR SWITCHING BETWEEN TABS
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    status: false,
    message: "",
  });

  let [popUp, setPopUp] = useState(false); // STATE FOR POPOUT MODAL

  let [jobDetailModal, setJobDetailModal] = useState(false); // STATE FOR JOB DELIVERY DETAIL POPOUT MODAL

  const printRef = useRef();
  // to handle printing
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const popUpHandler = () => {
    // FUNCTION TO HANDLE POPOUT
    setPopUp((prev) => !prev);
  };

  const jobDetailModalHandler = () => { // FUNCTION TO CLOSE JOB DELIVERY DETAIL MODAL
    setJobDetailModal((prev) => !prev);
  };

  // FUNCTION TO HANDLE MESSAGE CHANGE
  const handleMessageChange = ({ target: { value } }) => {
    setMessageToSend(value);
  };

  // FUNCTION TO HANDLE FILE UPlOAD CHANGE
  const handleFileChange = ({ target: { files } }) => {
    setRequestStatus({ loading: false, status: false, message: "" }); // State to determine error state

    if (!files[0]) {
      // IF NO FILE SELECTED RETURN
      return;
    }
    let fileType = files[0].type.split('/')[0].toLowerCase()
    if (fileType == 'video' && files[0].size > Number(process.env.REACT_APP_MAX_VIDEO_FILE_SIZE)) { // return if video file is more than 30mb
      setRequestStatus({
        loading: false,
        status: false,
        message: `File must be <= ${Number(process.env.REACT_APP_MAX_VIDEO_FILE_SIZE)/1048576} mb`,
      });
      setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
      return;
    }
    if (fileType != 'video' && files[0].size > Number(process.env.REACT_APP_MAX_FILE_SIZE)) { // return if other files is more than 1mb
      setRequestStatus({
        loading: false,
        status: false,
        message: `File must be <= ${Number(process.env.REACT_APP_MAX_FILE_SIZE)/1048576} mb`,
      });
      setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
      return;
    }
    if (filesToSend.length >= Number(process.env.REACT_APP_TOTAL_NUM_FILE)) {
      setRequestStatus({
        loading: false,
        status: false,
        message: `Total number of attachment is ${Number(
          process.env.REACT_APP_TOTAL_NUM_FILE
        )}`,
      });
      setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
      return;
    }
    // INCLUDE FILE IF NO ERROR
    // setFilesToSend((prev) => [...prev, files[0]]); //for sending multiple file if need be
    setFilesToSend([files[0]])
  };

  // FUNCTION TO CLEAR ALL TYPED MESSAGE OR FILES
  const handleClearAll = ({ target: { name } }) => {
    if (selectedTab == "Send Message") {
      setMessageToSend("");
    } else if (selectedTab == "Send Files") {
      setFilesToSend([]);
    } else {
      return;
    }
  };

  // FUNCTION TO REMOVE AND IMAGE
  const handleRemoveImage = (imageToDelete) => {
    setFilesToSend((prev) =>
      prev.filter((item) => item.name != imageToDelete.name)
    );
  };

  // FUNCTION TO SEND TASK MESSAGE
  const sendTaskMessage = () => {
    let reqData = {
      message: messageToSend,
      msg_type: "TEXT",
      contract: props.details.contract,
    };
    if (!reqData.message) {
      setRequestStatus({
        loading: false,
        status: false,
        message: "Message is empty",
      });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }
    setRequestStatus({ loading: true, status: false, message: "" });
    ApiCall.sendTaskMessage(reqData)
      .then((res) => {
        if (res.status != 200 || res.data.internal_return < 0) {
          setRequestStatus({
            loading: false,
            status: false,
            message: "Message could not be sent, try again later",
          });
          return;
        }
        setRequestStatus({
          loading: false,
          status: true,
          message: "Message Sent Successfully",
        });
        // function to trigger socket to emit 'send_message'
        sendMessage(messageToSend, `${props.details.contract}-${props.details.contract_uid}`)
        
        props.reloadActiveJobList((prev) => !prev); // MAKES ACTIVE JOB MESSAGE LIST TO RELOAD
        setMessageToSend(""); // SENDS MESSAGE TO SEND BACK TO EMPTY STRINGS
      })
      .catch((error) => {
        setRequestStatus({
          loading: false,
          status: false,
          message: "Opps! something went wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setRequestStatus({ loading: false, status: false, message: "" });
        }, 5000);
      });
  };

  // FUNCTION TO SEND FILES
  const sendFile = async () => {
    setRequestStatus({ loading: true, status: false, message: "" });

    if (!filesToSend.length) {
      // checks if file to send is empty
      setRequestStatus({
        loading: false,
        status: false,
        message: "No File(s) selected",
      });
      return setTimeout(() => {
        setRequestStatus({ loading: false, status: false, message: "" });
      }, 5000);
    }
    // const fileToBase64 = async () => {
    //   // Converts file data to base64 string
    //   try {
    //     const base64String = await convertFileToBase64(filesToSend[i]);
    //     return base64String;
    //   } catch (error) {
    //     return false;
    //   }
    // };

    let reqData = {
      // file_name: filesToSend[i].name,
      // file_size: filesToSend[i].size,
      // file_type: "image/png",
      // file_data: await fileToBase64(),
      msg_type: "FILE",
      contract: props.details.contract,
      file: filesToSend[0],
    };

    ApiCall.sendFilesNew(reqData)
      .then((res) => {
        if(res.status != 200 || res.data.internal_return < 0){
          setRequestStatus({loading: false, status: false, message: 'File could not be sent, try again later'})
          return
        }
        setRequestStatus({loading: false, status: true, message: 'File Uploaded Successfully'})
        // props.reloadActiveJobList(prev => !prev) // MAKES ACTIVE JOB MESSAGE LIST TO RELOAD
        // setFilesToSend([]) // SETS FILES TO SEND TO SEND BACK TO EMPTY ARRAY
      })
      .catch((error) => {
        setRequestStatus({loading: false, status: false, message: 'Opps! something went wrong'})
      })
      .finally(() => {
          setFilesToSend([]); // SETS FILES TO SEND TO SEND BACK TO EMPTY ARRAY
          props.reloadActiveJobList((prev) => !prev); // MAKES ACTIVE JOB MESSAGE LIST TO RELOAD
          setTimeout(() => {
            setRequestStatus({ loading: false, status: false, message: "" });
          }, 5000);
      });
  };

  // FUNCTION TO CHECK IF TASK PASS DUE IS REACHED
  let isPassedDue = () => {
    // console.log('TESTING',new Date() > new Date(props.details?.delivery_date) )
    if (new Date() > new Date(props.details?.delivery_date)) {
      setPassDue(true);
    } else {
      setPassDue(false);
    }
  };

  useEffect(() => {
    if (!passDue) {
      let passDueInterval = setInterval(() => {
        isPassedDue();
      }, 1000);
      return () => {
        clearInterval(passDueInterval);
      };
    }
  }, [passDue]);

  let thePrice = PriceFormatter(
    props.details?.price * 0.01,
    props.details?.currency_code,
    props.details?.currency
  );


  useEffect(()=>{
    // calls function to add user to a room
    joinRoom(`${props.details.contract}-${props.details.contract_uid}`)
  },[props.details.contract, props.details.contract_uid])

  return (
    <Layout>
      <div className="py-[20px] bg-white dark:bg-black dark:text-white px-4 rounded-2xl shadow-md md:flex justify-between items-start gap-8">
        {/* job title */}
        <div className="w-full">
          <div className="w-full flex justify-start space-x-3 items-center">
            <button
              type="button"
              className="min-w-[45px] h-auto text-[#374557] border border-sky-blue p-1 rounded-full"
              onClick={() => {
                if (props.details.pathname == "/manage-family") {
                  navigate(
                    props.details.pathname,
                    { state: { ...props.details.accountDetails } },
                    { replace: true }
                  );
                } else {
                  navigate(props.details.pathname, { replace: true });
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="skyblue"
              >
                <path d="M19 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H19v-2z" />
              </svg>
            </button>
            <h1 className="text-[20px] font-bold text-dark-gray dark:text-white tracking-wide">
              {props.details?.title && props.details.title}
            </h1>
          </div>

          <div className="w-full my-2">
            <p className="w-full text-base text-right text-sky-blue">
              {props?.details && props.details.job_to}
            </p>
            <div className="text-base text-slate-700 dark:text-white tracking-wide">
              <p className="font-semibold text-black dark:text-white">
                Description:{" "}
              </p>
              <p className="p-2 border border-sky-blue">
                {props?.details && props.details.description}
              </p>
            </div>
          </div>
        </div>
        {/* end of job title */}

        {/* job details */}
        <div className="min-w-[150px]">
          <button className="text-base text-sky-blue" onClick={jobDetailModalHandler}>Delivery Detail</button>
          {passDue ? (
            <div className="my-1">
              <p className="text-base text-slate-700">
                <span className="font-semibold">Due: </span>
                {props?.details && props.details.delivery_date.split(" ")[0]}
              </p>
              {props?.delivery_date && (
                <p className="py-2 text-base text-slate-700">
                  {props.details.delivery_date.split(" ")[1]}
                </p>
              )}
            </div>
          ) : (
            <div className="my-1 flex items-start gap-3">
              <p className="font-semibold">Due: </p>
              <div className="flex flex-col justify-between">
                <p className="text-base text-slate-700 tracking-wide">
                  <CountDown
                    lastDate={props?.details && props.details.delivery_date}
                  />
                </p>
                <div className="text-base text-slate-700 tracking-wide flex gap-[5px]">
                  <span>Hrs</span>
                  <span>Min</span>
                  <span>Sec</span>
                </div>
              </div>
            </div>
          )}

          <div className="my-1 text-base text-slate-700 tracking-wide flex items-center gap-3">
            <span className="font-semibold text-black dark:text-white">
            Reward:{" "}
            </span>
            <span className="">{thePrice}</span>
          </div>

          <div className="my-1 text-base text-slate-700 tracking-wide flex items-center gap-3">
            <span className="font-semibold text-black dark:text-white">
              Duration:{" "}
            </span>
            <span className="">
              {props.details?.timeline_days && props.details.timeline_days}{" "}
              day(s)
            </span>
          </div>
          <div className="my-1 text-base text-slate-700 tracking-wide flex items-center gap-3">
            <span className="font-semibold text-black dark:text-white">
              No:{" "}
            </span>
            <span className="">
              {props.details?.contract && props.details.contract}
            </span>
          </div>
        </div>
        {/* end of job details */}
      </div>

      <div className="my-4 py-[20px] bg-white dark:bg-black px-4 rounded-2xl shadow-md grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="w-full mb-4 border-b pb-4 lg:pb-0 lg:mb-0 lg:border-b-0">
          <div className="">
            <h1 className="text-lg font-bold text-dark-gray dark:text-white tracking-wide">
              Actions
            </h1>
            {/* <p className="my-3 py-1 text-base">
                  Waiting for the completion message from the client before you can approve.
                </p> */}
            <IndexJobActions details={props.details} />
          </div>

          {/* TEXTAREA SECTION */}
          <div className="mt-5">
            <div className="w-full">
                {/* switch button */}
                <div className="grid grid-cols-2">
                {tabs.map((item) => (
                    <TabButton 
                        key={item}
                        item={item}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                ))}
                </div>
                {/* switch button */}
                <div className="w-full bg-red-50 overflow-x-auto">
                  {selectedTab == "Send Message" ? (
                    <div className="p-2 w-full">
                      <textarea
                          className="p-4 w-full h-[150px] text-base text-slate-600 dark:text-white bg-white dark:bg-black outline-none"
                          // rows="10"
                          style={{ resize: "none" }}
                          name="message"
                          onChange={handleMessageChange}
                          value={messageToSend}
                          autoFocus
                      />
                    </div>
                  ) : (
                  <div className="p-2 w-full">
                    <div className="p-4 mb-2 h-[150px] text-base text-slate-600">
                        <div className="files flex">
                        <label
                            htmlFor="file"
                            className="custom-btn btn-gradient text-base text-white"
                        >
                            Select Files to Upload
                        </label>
                        <input
                            type="file"
                            id="file"
                            accept="image/*,video/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        </div>
                        <div className="selected_file my-2 overflow-y-auto">
                        {filesToSend.length > 0 &&
                            filesToSend.map((item, index) => (
                            <p key={index} className="flex items-center space-x-2">
                                <span>{item.name}</span>
                                <button
                                name="remove"
                                onClick={() => handleRemoveImage(item)}
                                className="px-2 flex justify-center items-center rounded-full border border-red-500 text-red-500"
                                >
                                x
                                </button>
                            </p>
                            ))}
                        </div>
                    </div>
                  </div>
                  )}
                  {/* Buttons Sections */}
                  <div className="p-2 grid grid-cols-1 xxs:grid-cols-3">
                      <div className="w-full col-span-3 col-start-1 xxs:col-span-2 xxs:col-start-2 flex justify-between items-center gap-4">
                          <button
                              type="button"
                              onClick={handleClearAll}
                              className="custom-btn border-gradient"
                          >
                              <span className="text-gradient">Clear</span>
                          </button>
                          {selectedTab == "Send Files" ? (
                              <button
                              onClick={sendFile}
                              type="button"
                              className="custom-btn btn-gradient text-white"
                              >
                              {requestStatus.loading ? (
                                  <LoadingSpinner size="6" color="sky-blue" />
                              ) : (
                                  <>
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="20"
                                      height="20"
                                      fill="white"
                                  >
                                      <path d="M12 2L2 12h3v8h14v-8h3L12 2zm0 16v-6h-2v6H7l5-5 5 5h-3z" />
                                  </svg>

                                  <span className="text-white">Upload Files</span>
                                  </>
                              )}
                              </button>
                          ) : (
                              <button
                              onClick={sendTaskMessage}
                              type="button"
                              className="custom-btn btn-gradient text-white"
                              >
                              {requestStatus.loading ? (
                                  <LoadingSpinner size="6" color="sky-blue" />
                              ) : (
                                  <span className="text-white">Send</span>
                              )}
                              </button>
                          )}
                      </div>
                  </div>
                  {/* end of Buttons Sections  */}
                </div>
            </div>

            {/* ERROR DISPLAY AND SUBMIT BUTTON */}
            <div className="w-full">
              {/* error or success display */}
              {requestStatus.message != "" &&
                (!requestStatus.status ? (
                  <div
                    className={`relative p-4 my-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                  >
                    {requestStatus.message}
                  </div>
                ) : (
                  requestStatus.status && (
                    <div
                      className={`relative p-4 my-4 text-green-700 bg-slate-200 border-slate-800 mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]`}
                    >
                      {requestStatus.message}
                    </div>
                  )
                ))}
            </div>
            {/* End of error or success display */}
          </div>
          {/* END OF TEXTAREA */}
        </div>

        {/* MESSAGE SECTION */}
        <div className="w-full flex flex-col">
          {props.details &&
            <div className="mb-4">
              <AttachFile
                data={props.details}
                showOnData={true}
                fontSize={'text-lg'}
              />
            </div>
          }
          <div className="flex justify-between items-center gap-5">
            <p className="w-full text-lg font-bold text-dark-gray dark:text-white tracking-wide flex items-center gap-2 justify-between"> 
              <span>Message</span>
              <button
              type="button"
              onClick={popUpHandler}
              className="text-[12px] tracking-wider text-gray-400 dark:text-slate-400"
            >
              View all
            </button>
            </p>
            {/* <button
              type="button"
              onClick={popUpHandler}
              className="btn-gradient text-base tracking-wide px-4 py-2 rounded-full text-white cursor-pointer flex justify-center items-center"
            >
              View all
            </button> */}
          </div>
          {props.activeJobMesList.loading ? (
            <LoadingSpinner size="16" color="sky-blue" />
          ) : (
            <ActiveJobMessage activeJobMesList={props.activeJobMesList} />
          )}
        </div>
        {/* END OF MESSAGE */}
      </div>

      {/* POPOUT SECTION */}
      {popUp && (
        <PopModal
          popUpHandler={popUpHandler}
          popUp={popUp}
          details={props.details}
          activeJobMesList={props.activeJobMesList}
          handlePrint={handlePrint}
          myRef={printRef}
        />
      )}
      {/* END OF POPOUT SECTION */}

      {/* Delivery Details Popout */}
      {jobDetailModal &&
        <JobDetailPopout
          action={jobDetailModalHandler}
          situation={jobDetailModal}
          jobDetail={props?.details?.job_description}
        />
      }
      {/* END Delivery Details Popout */}
    </Layout>
  );
}

export default ActiveJobs;

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

//POPOUT COMPONENT FUNCTION
const PopModal = ({
  popUpHandler,
  popUp,
  details,
  activeJobMesList,
  handlePrint,
  myRef,
}) => {
  return (
    <ModalCom action={popUpHandler} situation={popUp}>
      <div
        ref={myRef}
        className="message-modal-wrapper w-11/12 min-w-[350px] max-w-[700px] bg-white dark:bg-dark-white lg:rounded-2xl overflow-y-auto"
      >
        <div className="message-modal-header w-full flex items-center justify-between lg:px-10 lg:py-8 px-[30px] py-[23px] border-b dark:border-[#5356fb29]  border-light-purple">
          <h1 className="text-26 font-bold text-dark-gray dark:text-white tracking-wide">
            {details?.contract}
          </h1>
          <button
            type="button"
            className="text-[#374557] dark:text-red-500"
            onClick={popUpHandler}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                fill=""
                fillOpacity="0.6"
              />
              <path
                d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                fill="#"
                fillOpacity="0.6"
              />
            </svg>
          </button>
        </div>
        <div className="job-action-modal-body w-full px-10 py-8 gap-4">
          <div className="w-full flex flex-col items-center">
            {activeJobMesList.loading ? (
              <LoadingSpinner size="16" color="sky-blue" />
            ) : (
              <div className="message-table h-[500px] overflow-y-auto">
                <table className="wallet-activity w-full table-auto border-collapse text-left">
                  <thead className="border-b-2">
                    <tr className="text-slate-600">
                      <th className="p-2"></th>
                    </tr>
                  </thead>
                  {activeJobMesList?.data?.length ? (
                    <tbody>
                      {activeJobMesList?.data?.map((item, index) => (
                        <tr key={index} className="text-slate-500">
                          <td>
                            <div className="msg_box">
                              <div className="msg_header">
                                {item.msg_date} {item.msg_firstname}
                              </div>
                              <span
                                className="p-2"
                                dangerouslySetInnerHTML={{
                                  __html: item.message,
                                }}
                              ></span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : activeJobMesList.error ? (
                    <tbody>
                      <tr className="text-slate-500">
                        <td className="p-2" colSpan={4}>
                          Opps! an error occurred. Please try again!
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-slate-500">
                        <td className="p-2" colSpan={4}>
                          No Message Found!
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>

          {/* btn */}
          <div className="flex justify-end items-center">
            <div className="py-3 w-full lg:w-1/2 flex justify-between items-center">
              <button
                onClick={handlePrint}
                type="button"
                className="w-20 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
              >
                <span className="text-white">Print</span>
              </button>
              <button
                onClick={popUpHandler}
                type="button"
                className="w-20 h-11 flex justify-center items-center border-gradient text-base rounded-full text-white"
              >
                <span className="text-gradient">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCom>
  );
};
