import React, { useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import dataImage2 from "../../assets/images/data-table-user-2.png";
import dataImage3 from "../../assets/images/data-table-user-3.png";
import dataImage4 from "../../assets/images/data-table-user-4.png";
import SelectBox from "../Helpers/SelectBox";

import PaginatedList from "../Pagination/PaginatedList";
import { handlePagingFunc } from "../Pagination/HandlePagination";

export default function ActiveJobMessage({ activeJobMesList }) {
    // const [currentPage, setCurrentPage] = useState(0);
    // const indexOfFirstItem = Number(currentPage);
    // const indexOfLastItem = Number(indexOfFirstItem)+Number(process.env.REACT_APP_ITEM_PER_PAGE);
    // const currentActiveJobMesList = activeJobMesList?.data?.slice(indexOfFirstItem, indexOfLastItem);
  
    // const handlePagination = (e) => {
    //   handlePagingFunc(e,setCurrentPage)
    // }

  return (
    <div className="w-full h-full max-h-[345px] overflow-y-auto">
        <table className="wallet-activity w-full table-auto border-collapse text-left">
            <thead className='border-b-2'>
                <tr className='text-slate-600'>
                <th className="p-2"></th>
                </tr>
            </thead>
            {activeJobMesList?.data?.length ?
            (
            <tbody>
                {activeJobMesList.data.map((item, index) => 
                {
                    let imageLink = `${activeJobMesList?.image}${localStorage.getItem('session_token')}/contracts/${item.msg_uid}`
                    return (
                        <tr key={index} className='text-slate-500'>
                            <td>
                                <div className={`msg_box ${item.who}`}>
                                    <div className="msg_header">{item.msg_date} {item.msg_firstname}</div>
                                    {item.msg_type == 'FILE' ?
                                    <a href={imageLink} target="_blank" className="p-2" dangerouslySetInnerHTML={{__html: item.message}}></a>
                                    :
                                    <span className="p-2" dangerouslySetInnerHTML={{__html: item.message}}></span>
                                    }
                                </div>
        
                            </td>
                        </tr>
                    )
                }
                )}
            </tbody>
            )
            :
            activeJobMesList.error ? 
            (
            <tbody>
                <tr className='text-slate-500'>
                    <td className="p-2" colSpan={4}>Opps! an error occurred. Please try again!</td>
                </tr>
            </tbody>
            )
            :
            <tbody>
                <tr className='text-slate-500'>
                    <td className="p-2" colSpan={4}>No Message Found!</td>
                </tr>
            </tbody>
            }
        </table>
    </div>

    // <div className='flex flex-col justify-between'>
    // PAGINATION BUTTON
    // <PaginatedList onClick={handlePagination} prev={currentPage == 0 ? true : false} next={currentPage+Number(process.env.REACT_APP_ITEM_PER_PAGE) >= activeJobMesList?.data?.length ? true : false} data={activeJobMesList?.data} start={indexOfFirstItem} stop={indexOfLastItem} />
    // END OF PAGINATION BUTTON
    // </div>
  )
}
